.uppercase {
  text-transform: uppercase;
}

.mb0 {
  margin-bottom: 0;
}

.status {
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  border-radius:0.5rem;
}

.pending-status {
  @extend .status;
  background: $primary;
  color: white;
}
